var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("quickmapping-dialog.title")))])]),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("quickmapping-dialog.value-to-map-field"))+": "+_vm._s(_vm.value.content)+" ("+_vm._s(_vm.value.attribute.name)+") ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('quickmapping-dialog.value-target-field'),"rules":[
                v => !!v || _vm.$t('generic.required-field'),
                v =>
                  v === null ||
                  v === '' ||
                  v.length >= 1 ||
                  _vm.$t('validation.min', { num: 1 }),
                v =>
                  v === null ||
                  v === '' ||
                  v.length <= 254 ||
                  _vm.$t('validation.max', { num: 254 })
              ],"counter":"255"},model:{value:(_vm.outputValue),callback:function ($$v) {_vm.outputValue=$$v},expression:"outputValue"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.closeModelDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("form.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.loading,"text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("form.save"))+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }